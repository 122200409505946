import React, { useContext } from "react";
// import images
import Image1 from "../img/commands/7.webp";
import Image2 from "../img/commands/9.webp";
import Image3 from "../img/commands/10.webp";
import Image4 from "../img/commands/8.webp";
//import link
import { Link } from "react-router-dom";

import { motion } from "framer-motion";
import { transition1, transition3 } from "../transitions";
import { CursorContext } from "../context/CursorContext";
import { useTranslation } from "react-i18next";
const Commands = () => {
  const { t } = useTranslation()
  const { mouseEnterHandler, mouseLeaverHandler } = useContext(CursorContext);
  return (
    <section
      initial={{ opacity: 0, y: "10%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "10%" }}
      transition={transition1}
      className="section"
    >
      <div className="container mx-auto h-full relative">
        <div onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaverHandler} className="flex flex-col xl:flex-row h-full items-center justify-start gap-x-24 text-center xl:text-left pt-24 xl:pt-36 pb-8 xl:px-20 overflow-auto min-w-max h-">
          {/* text */}
          <motion.div
            initial={{ opacity: 0, y: "-20%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "-20%" }}
            transition={transition1}
            className="flex flex-col xl:items-start"
          >
            <h1 className="h1 height xl:text-[64px]">
              <a
                href="https://twitter.com/listenme_bot"
                target="_blank"
                rel="noreferrer"
                className="colorful text-[48px] xl:text-[64px]"
              >
                {t('bestInClass')}
              </a>
              <br />
              {t('Commands')}
            </h1>
            <p className="desc-about-semibold mb-4 max-w-sm font-secondary self-center xl:self-start px-1">
              {t('With 3 categories')} (
              <bold className="bold">{t('Music, Information & Utils')}</bold>),
              <br />
              {t('Listen will be a')} <bold className="bold">{t('lifesaver')}</bold> {t('to your community.')}
            </p>
            <Link
              initial={{ scale: "20%", x: "-20%" }}
              animate={{ scale: "100%", x: 0 }}
              to={
                "https://discord.com/api/oauth2/authorize?client_id=777401960793636934&permissions=277129317376&scope=bot%20applications.commands"
              }
              className="btn3 mb-[30px] xl:text-[20px]"
              target="_blank"
              rel="noreferrer"
            >
              {t('add.listen')}
            </Link>
          </motion.div>
          {/* img grid */}
          <div className="grid grid-cols-2 gap-2 xl:gap-4 ">
            {/* images */}
            <div className="bordered max-w-[250px] xl:max-w-[320px] h-[187px] xl:h-[220px] bg-accent overflow-hidden">
              <motion.img
                initial={{ opacity: 0, y: "0%" }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: "0%" }}
                transition={transition1}
                className="object-cover h-full xl:h-[220px] hover:scale-110 transition-all duration-400"
                src={Image1}
                alt=""
              ></motion.img>
            </div>
            
            <div className="bordered max-w-[250px] xl:max-w-[320px] h-[187px] xl:h-[220px] bg-accent overflow-hidden">
              <motion.img
                initial={{ opacity: 0, y: "0%" }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: "0%" }}
                transition={transition1}
                className="object-cover h-full xl:h-[220px] hover:scale-110 transition-all duration-400"
                src={Image2}
                alt=""
              ></motion.img>
            </div>
            <div className="bordered max-w-[250px] xl:max-w-[320px] h-[187px] xl:h-[220px] bg-accent overflow-hidden">
              <motion.img
                initial={{ opacity: 0, y: "0%" }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: "0%" }}
                transition={transition1}
                className="object-cover h-full xl:h-[220px] hover:scale-110 transition-all duration-400"
                src={Image3}
                alt=""
              ></motion.img>
            </div>
            <div className="bordered max-w-[250px] xl:max-w-[320px] h-[187px] xl:h-[220px] bg-accent overflow-hidden">
              <motion.img
                initial={{ opacity: 0, y: "0%" }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: "0%" }}
                transition={transition1}
                className="object-cover h-full xl:h-[220px] hover:scale-110 transition-all duration-400"
                src={Image4}
                alt=""
              ></motion.img>
            </div>
          </div>
        </div>
      </div>
      <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3730111662635989"
            crossorigin="anonymous"
          ></script>
          <ins
            class="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-3730111662635989"
            data-ad-slot="7577091439"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    </section>
  );
};

export default Commands;
