import React, { useContext } from "react";
import { motion } from "framer-motion";
import { transition1 } from "../transitions";
import { CursorContext } from "../context/CursorContext";
// import icons
import { ImTwitter, ImPinterest, ImLinkedin, ImGithub } from "react-icons/im";

const Socials = () => {
  const { mouseEnterHandler, mouseLeaverHandler } = useContext(CursorContext);
  return (
    <div
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaverHandler}
      className="hidden md:flex ml-24"
    >
      <motion.ul
        initial={{ opacity: 0, y: "150%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "150%" }}
        transition={transition1}
        className="flex gap-x-4"
      >
        <li>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noreferrer"
            className="twitter"
          >
            <ImTwitter />
          </a>
        </li>
        <li>
          <a
            href="https://www.pinterest.com"
            target="_blank"
            rel="noreferrer"
            className="pinterest"
          >
            <ImPinterest />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/cau%C3%A3-gabriel-66458a268/"
            target="_blank"
            rel="noreferrer"
            className="linkedin"
          >
            <ImLinkedin />
          </a>
        </li>
        <li>
          <a
            href="https://www.github.com/gabcaua"
            target="_blank"
            rel="noreferrer"
            className="github"
          >
            <ImGithub />
          </a>
        </li>
      </motion.ul>
    </div>
  );
};

export default Socials;
