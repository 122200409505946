import React, { useContext } from "react";
// import components
import Socials from "./Socials";
import Logo from "../img/header/logo2.svg";
import MobileNav from "./MobileNav";
import { motion } from "framer-motion";
import { transition1 } from "../transitions";
import { CursorContext } from "../context/CursorContext";
// import link
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation()
  const { mouseEnterHandler, mouseLeaverHandler } = useContext(CursorContext);
  return (
    <motion.header
      initial={{ y: "-100%" }}
      animate={{ y: 0 }}
      exit={{ y: "-100%" }}
      transition={transition1}
      className="fixed bg-accent w-full px-[32px] lg:px-[100px] z-30 h-[70px] md:h-[80px] xl:h-[100px] flex items-center"
    >
      <div className="flex flex-col lg:flex-row lg:items-center w-full justify-between">
        {/* Logo */}
        <Link
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaverHandler}
          to={"/"}
          className="max-w-[200px]"
        >
          <motion.img
            initial={{ opacity: 0, y: "150%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "150%" }}
            transition={transition1}
            src={Logo}
            alt="header logo"
          />
        </Link>
        {/* nav - initially hidden - show on desktop mode */}
        <motion.nav
          initial={{ opacity: 0, y: "100%" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "100%" }}
          transition="linear"
          className="hidden lg:flex gap-x-12 font-semibold"
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaverHandler}
        >
          <Link
            to={"/"}
            className="text-[#696c6d] hover:text-primary transition"
          >
            {t("Intro")}
          </Link>
          <Link
            to='/Features'
            className="text-[#696c6d] hover:text-primary transition"
          >
            {t("Features")}
          </Link>
          <Link
            to='/Commands'
            className="text-[#696c6d] hover:text-primary transition"
          >
            {t("Commands")}
          </Link>
        </motion.nav>
      </div>
      {/* socials */}
      <Socials />
      {/* mobile nav */}
      <MobileNav />
    </motion.header>
  );
};

export default Header;
