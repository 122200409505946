import React, { useState } from "react";
// import icons
import { IoMdClose } from "react-icons/io";
import { CgMenuRight } from "react-icons/cg";
// import link
import { Link } from "react-router-dom";
// import motion
import { motion } from "framer-motion";
import { transition1 } from "../transitions";
import { useTranslation } from "react-i18next";
// menu variants
const menuVariants = {
  hidden: {
    x: "100%",
  },
  show: {
    x: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.9],
    },
  },
};

const MobileNav = () => {
  const { t } = useTranslation()
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <nav className="text-primary lg:hidden">
      {/* nav open button */}
      <motion.div
        initial={{ opacity: 0, y: "150%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "150%" }}
        transition={transition1}
        onClick={() => setOpenMenu(true)}
        onMouseEnter={() => setOpenMenu(true)}
        className="text-3xl cursor-pointer pl-5"
      >
        <CgMenuRight />
      </motion.div>
      {/*menu*/}
      <motion.div
        variants={menuVariants}
        initial="hidden"
        animate={openMenu ? "show" : ""}
        className="bg-white shadow-2xl w-full absolute top-0 right-0 max-w-xs h-screen z-20"
      >
        {/* icon */}
        <div
          onClick={() => setOpenMenu(false)}
          onMouseEnter={() => setOpenMenu(false)}
          className="text-4xl absolute z-30 left-4 top-14 text-primary cursor-pointer"
        >
          <IoMdClose />
        </div>

        {/* menu list */}
        <ul className="h-full flex flex-col justify-center items-center gap-y-8 text-primary font-primary font-bold text-3xl">
          <li>
            <Link to="/">{t('Intro')}</Link>
          </li>
          <li>
            <Link to={'/Features'}>{t('Features')}</Link>
          </li>
          <li>
            <Link to={'/Commands'}>{t('Commands')}</Link>
          </li>
        </ul>
      </motion.div>
    </nav>
  );
};

export default MobileNav;
