import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import "./index.css";
import App from "./App.js";
import "./i18n";
//import cursor context provider
import CursorProvider from "./context/CursorContext";

createRoot(document.getElementById("root")).render(
  <CursorProvider>
    <App />
  </CursorProvider>
);

/* const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CursorProvider>
  <React.StrictMode>
    
    <App />
  </React.StrictMode>
  </CursorProvider>
); */
