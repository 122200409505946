import React from "react";
//import pages
import Intro from "../pages/Intro";
import Features from "../pages/Features";
import Commands from "../pages/Commands";
import PrivacyStatement from "../pages/_PrivacyStatement";
import NotFound from "../pages/NotFound";

// import routes route & useLocation hook
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

const AnimRoutes = () => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <AnimatePresence initial={true} mode="wait">
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<Intro />} />
        <Route path='/Features' element={<Features />} />
        <Route path='/Commands' element={<Commands />} />
        <Route path='/PrivacyStatement' element={<PrivacyStatement />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimRoutes;
