import React, { useContext } from "react";
// import images
import TadaImg from "../img/features/tada.svg";
// import link
// import motion
import { motion } from "framer-motion";
//import transition
import { transition1, transition2 } from "../transitions.js";
//import cursor context
import { CursorContext } from "../context/CursorContext";
import { useTranslation } from "react-i18next";
import WomanImg from "../img/features/woman2.webp";
import WomanImg_br from "../img/features/woman2-ptBR.png";
const Features = () => {
  const { t } = useTranslation();
  const { mouseEnterHandler, mouseLeaverHandler } = useContext(CursorContext);
  return (
    <motion.section
      initial={{ opacity: 0, y: "50%" }}
      animate={{ opacity: 1, y: "0%" }}
      exit={{ opacity: 0, y: "50%" }}
      transition={transition2}
      className="section"
    >
      <div className="container mx-auto h-full relative overflow-auto">
        {/*text & img wrapper */}
        <div className="flex flex-col lg:flex-row h-auto lg:h-full items-center justify-center gap-x-24 lg:gap-x-32 text-center lg:text-left lg:pt-24 overflow-hidden">
          {/*text */}
          <div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaverHandler}
            className=" flex-1 pt-32 pb-14 lg:pt-14 lg:w-auto z-10 flex flex-col justify-center lg:items-start"
          >
            <h1 className="h1 order-first colorful">
              {t("feature")}
              <br />
              {t("rich")}
            </h1>
            <p className="desc-about-semibold mb-12 max-w-sm text-[24px]">
              {t("innovated")}
              <bold className="bold"> {t("syncedLyrics")}</bold>{" "}
              {t("andLiveCards")}
              <bold className="bold">{t("liveCards")}</bold>
              {t("toThePlay")}
            </p>
            <img
              className="align-center center self-center w-12 lg:h-16 lg:w-16 lg:top-0 lg:self-start lg:relative lg:left-48"
              src={TadaImg}
              alt="party popper emoji"
            ></img>
          </div>
          {/*image */}
          <motion.div
            initial={{ opacity: 0, y: "90%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "90%" }}
            transition={transition1}
            className="flex max-h-300 order-last lg:order-last mx-auto px- overflow-auto"
          >
            <img
              className="scale-90"
              src={navigator.language === "pt-BR" ? WomanImg_br : WomanImg}
              alt="promo card"
            />
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default Features;
