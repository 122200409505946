import React, { useEffect, useContext } from "react";
// import images
import WomanImg from "../img/intro/lite-3.webp";
import Avatar from "../img/intro/avatar.webp";
// import link
import { Link } from "react-router-dom";
// import motion
//import transition
import { transition1 } from "../transitions.js";
//cursor context
import { CursorContext } from "../context/CursorContext";
import Typewriter from "typewriter-effect";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  const { mouseEnterHandler, mouseLeaverHandler } = useContext(CursorContext);
  useEffect(() => {
    document.title = "Listen — your next Discord music bot!";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = "https://cgsilva.com.br/newFavicon.ico";
  }, []);
  return (
    <>
      <section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={transition1}
        className="section"
      >
        <div className="md:px-[6rem] lg:px-[6rem] xl:px-[12rem] mx-auto h-full relative overflow-auto ">
          {/* text & img wrapper */}
          <div className="flex flex-col justify-center">
            {/* text */}
            <div
              initial={{ opacity: 0, y: '-10%' }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: '-10%' }}
              transition={transition1}
              onMouseEnter={mouseEnterHandler}
              onMouseLeave={mouseLeaverHandler}
              className="w-full pt-36 pb-14 lg:pt-0 lg:pb-0 lg:w-auto z-10 lg:absolute flex flex-col justify-center items-center lg:items-start text-center lg:text-left"
            >
              <div className=" min-h-[12rem] lg:h-[16rem] height mx-screen">
                <h1
                  className="h1" // optional
                >
                  <Typewriter
                    options={{
                      strings: t("type"),
                      loop: true,
                      cursor: "",
                      delay: 50,
                      autoStart: true
                    }}
                  />
                </h1>
              </div>
              <p className="text-[26px] lg:text-[36px] w-full text-center lg:text-left font-secondary text-grey mb-2 lg:mb-4">
                {t("Meet")}
                <a
                  href="https://discord.com/api/oauth2/authorize?client_id=777401960793636934&permissions=277129317376&scope=bot%20applications.commands"
                  target="_blank"
                  rel="noreferrer"
                  className="Listen font-listen text-[26px] lg:text-[36px] mb-4 lg:mb-12 font-bold"
                >
                  Listen
                </a>
                {t("Intro.desc")}
                <br />
                {t("Intro.desc.two")}
              </p>
              <div className="flex justify-start mb-4 lg:mb-8 font-listen text-[18px]">
                <img
                  src={Avatar}
                  alt=""
                  className="mr-4 w-[28px] h-[28px] avatar relative"
                />
                <a
                  href="https://discord.com/users/712103766173941811/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("inventedBy")}
                </a>{" "}
                <a
                  href="https://discord.com/users/712103766173941811/"
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold ml-1"
                >
                  Cauã
                </a>
              </div>
              <div className="flex flex-col md:flex-row justify-center gap-2 md:gap-8">
                <Link
                  to={
                    "https://discord.com/api/oauth2/authorize?client_id=777401960793636934&permissions=277129317376&scope=bot%20applications.commands"
                  }
                  className="btn text-[16px] mb-0 lg:text-[18px]"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("getListen")}
                </Link>
                <Link
                  initial={{ scale: "80%", x: "-20%" }}
                  animate={{ scale: "100%", x: 0 }}
                  to={"/Commands"}
                  className="btn text-[16px] mb-[10px] lg:text-[18px]"
                >
                  {t("commandsBtn")}
                </Link>
              </div>
            </div>
            {/* img */}
            <div className="flex justify-center lg:justify-end max-h-max lg:max-h-max ">
              <div
                initial={{ scale: 0 }}
                animate={{ scale: 1, y: 0 }}
                exit={{ scale: 0 }}
                transition={transition1}
                className="relative lg:-right-40 overflow-auto rounded top-[-140px] md:top-0"
              >
                <img
                  onMouseEnter={mouseEnterHandler}
                  onMouseLeave={mouseLeaverHandler}
                  src={WomanImg}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3730111662635989"
          crossorigin="anonymous"
        ></script>
        <ins
          className="adsbygoogle block"
          data-ad-client="ca-pub-3730111662635989"
          data-ad-slot="7577091439"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      </section>
    </>
  );
};

export default Home;
