const transition1 = {
  delay: 0,
  duration: 0.8,
  //ease: [0.01, 0.05, 0.6, 0.96],
  ease: [0.43, 0.13, 0.23, 0.96],
};

const transition2 = {
  delay: 0,
  duration: 0.8,
  //ease: [0.01, 0.05, 0.6, 0.96],
  ease: [0.03, 0.05, 0.23, 0.96],
};

const transition3 = {
  delay: 0,
  duration: 1,
  //ease: [0.01, 0.05, 0.6, 0.96],
  ease: [0.25, 0.5, 0.75, 1],
};
export {transition1, transition2, transition3}
